import React from "react"
import * as styles from "../styles/header.module.css"

export default function Header() {
  return (
    <header className={styles.header}>
      <p>
         Designar <br />& Frontend-utviklar
      </p>
    </header>
  )
}
